import React from 'react';
import { Helmet } from 'react-helmet';
import { Package } from '../Classes/Package';
import Banner from './Banner/Banner';
import Breadcrumb from './Breadcrumb/Breadcrumb';
import Layout from './Layout/layout';
import Search from '../Views/Website/Search/Search';

const SearchPage: React.FC = (props: any) => {
  const Packages: Package[] = props.pageContext.Packages;

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Search</title><meta charSet='utf-8' />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "", Text: "Search" }
      ]} />
      {Packages ? <Search location={props.location} PackagesData={Packages} /> : <></>}
    </Layout>
  );
};

export default SearchPage;
