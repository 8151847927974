// extracted by mini-css-extract-plugin
export var Active = "Search-module--Active--c4020";
export var Alphabet = "Search-module--Alphabet--fd0fc";
export var Documents = "Search-module--Documents--27834";
export var DocumentsInner = "Search-module--DocumentsInner--6b61e";
export var Filter = "Search-module--Filter--89583";
export var FilterButton = "Search-module--FilterButton--415aa";
export var Loading = "Search-module--Loading--593ef";
export var Message = "Search-module--Message--6e808";
export var Nav = "Search-module--Nav--32759";
export var NavInner = "Search-module--NavInner--ce3c7";
export var Search = "Search-module--Search--e5237";
export var SearchInput = "Search-module--SearchInput--454fa";
export var Section = "Search-module--Section--696d1";
export var SectionHeader = "Search-module--SectionHeader--595e3";
export var SectionInner = "Search-module--SectionInner--f0120";
export var Show = "Search-module--Show--d0170";