import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { benefitsList } from '../../../Classes/Benefits';
import { Package } from '../../../Classes/Package';
import { Articles, BlogsList } from '../../../Data/Articles';
import { EmploymentStatus, StatusColours } from '../../../Enums/EmploymentStatus';
import * as styles from './Search.module.scss';

type Result = {
  Route: string;
  Text: string;
  Description: string;
  Category: string;
}

interface Props {
  PackagesData: Package[];
  location: any;
}

const Search: React.FC<Props> = ({ location, PackagesData }) => {
  const alphabet: string[] = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
  const filters: string[] = ["Package", "Document", "Employment Status", "Benefit", "Blog", "Article"];

  const [search, setSearch] = useState<string>("");
  const [searchFilters, setSearchFilters] = useState<string[]>(filters);
  const [lettersFilters, setLettersFilters] = useState<string[]>(alphabet);
  const [showFilter, setShowFilter] = useState<boolean>(true);

  useEffect(() => {
    Search(location);
  }, [location.search])

  const Search = (Location: any) => {
    let search = Location.search;
    if (search) {
      let params: string[] = search.split("&");
      let index: number = params.findIndex(x => x.toLowerCase().includes("search="));
      if (index >= 0)
        setSearch(params[index].split("=")[1]);
    } else {
      setSearch("");
    }
  }

  const GetSearch = () => {
    const results: Result[] = [] as Result[];

    PackagesData.filter((p) => p.ID < 4).map((p) => {
      results.push({
        Route: `/Packages/${p.Title}`,
        Text: p.Title,
        Category: "Package",
        Description: p.Description
      } as Result);

      p.Documents.map((d) => {
        results.push({
          Route: `/Document/${d.Title.replace(/ /g, "-")}`,
          Text: d.Title,
          Category: "Document",
          Description: p.Description
        } as Result);
      })
    })

    StatusColours.filter((s) => s.Information.Description).map((s) => {
      results.push({
        Route: `/EmploymentStatus/${s.Status}`,
        Text: EmploymentStatus[s.Status],
        Category: "Employment Status",
        Description: s.Information.Description
      } as Result);
    })

    benefitsList.map((b) => {
      results.push({
        Route: `/Benefits/${encodeURIComponent(b.Title)}`,
        Text: b.Title,
        Category: "Benefit",
        Description: b.Description
      } as Result);
    })

    BlogsList.map((b) => {
      results.push({
        Route: b.Route,
        Text: b.Title,
        Category: "Blog",
        Description: b.Description
      } as Result);
    })

    Articles.map((a) => {
      results.push({
        Route: a.Route,
        Text: a.Title,
        Category: "Article",
        Description: a.Description
      } as Result);
    })

    return results.sort((a, b) => a.Text.localeCompare(b.Text))
  }

  const ToggleSearchFilter = (ID: string) => {
    let sf = JSON.parse(JSON.stringify(searchFilters));
    let fil = searchFilters.findIndex(x => x === ID);
    if (fil >= 0)
      sf.splice(fil, 1);
    else
      sf.push(ID);
    setSearchFilters(sf);
  }

  const ToggleLettersFilter = (ID: string) => {
    let sf = JSON.parse(JSON.stringify(lettersFilters));
    let fil = lettersFilters.findIndex(x => x === ID);
    if (fil >= 0)
      sf.splice(fil, 1);
    else
      sf.push(ID);
    setLettersFilters(sf);
  }

  return <>
    <div className={styles.Search}>
      <div className={styles.SearchInput}>
        <i><FontAwesomeIcon icon={faSearch} /></i>
        <input autoFocus={true} type="search" placeholder="Search..." value={search} onChange={(e) => { setSearch(e.target.value); navigate(`?search=${e.target.value}`) }} />
      </div>

      <div className={styles.Alphabet}>
        <button className={lettersFilters.length === alphabet.length ? styles.Active : ""} onClick={() => setLettersFilters(alphabet)}>All</button>

        {
          alphabet.map(a => {
            return <button className={lettersFilters.includes(a) ? styles.Active : ""} onClick={() => ToggleLettersFilter(a)}>{a}</button>
          })
        }

        <button onClick={() => setLettersFilters([] as string[])}>Clear</button>
      </div>
    </div>

    <div className={styles.Documents}>
      <button className={`${styles.FilterButton} `} onClick={() => setShowFilter(!showFilter)}>
        <i>
          <FontAwesomeIcon icon={faFilter} />
        </i>
        Filter

      </button>

      <ul className={`${styles.Filter} ${showFilter ? styles.Show : ""}`}>
        <li>
          <p>Filter</p>
        </li>
        {
          filters.map(f => {
            return <li key={`${f}`}>
              <label>
                <input checked={searchFilters.includes(f)} type="checkbox" onClick={() => ToggleSearchFilter(f)} />
                <span>{f}</span>
              </label>
            </li>
          })
        }
      </ul>

      <div className={styles.DocumentsInner}>
        {
          alphabet.filter(a => lettersFilters.includes(a)).filter((a) => GetSearch().find(s => s.Text.toLowerCase().startsWith(a.toLowerCase()) && searchFilters.includes(s.Category) && s.Text.toLowerCase().includes(search.toLowerCase()))).map((a) => {
            return <>
              <h2>{a}</h2>
              {GetSearch().filter(s => s.Text.toLowerCase().startsWith(a.toLowerCase())).filter(s => s.Text.toLowerCase().includes(search.toLowerCase()) && searchFilters.includes(s.Category)).map(s => {
                return <Link to={s.Route}>
                  <p>
                    {s.Text}
                    {s.Description ? <small>{s.Description}</small> : <></>}
                  </p>
                  <span><b>{s.Category}</b></span>
                </Link>
              })}
            </>
          })
        }
      </div>
    </div>
  </>;
};

export default Search;
